<template>
    <div id="header">
        <div>
          <router-link to="/"><img class="logo" src="../assets/img/logo.svg" alt="logo portfolio"></router-link>
        </div>
        <div id="menu" class="menu">
          <nav>
            <div class="section">
              <a href="/#competence">Compétences</a>
            </div>
            <div class="section">
              <a href="/#experience">Expériences</a>
            </div>
            <div class="section">
              <a href="/#formation">Formations</a>
            </div>
            <div class="section">
              <a href="/#footer">Contact</a>
            </div>
            <div class="btn_default section">
              <router-link to="/projets">Projets</router-link>
            </div>
          </nav>
        </div>
      </div>
</template>

<script>
export default {
  name: 'Header',
}
</script>

