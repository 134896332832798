<template>
    <div id="header">
      <div class="menu_mobile" id="menu-mobile" v-on:click=OpenMenu()>
        <p> Menu </p>
      </div>
      <div class="drop_menu_mobile" id="drop-menu-mobile">
        <div id="menu" class="menu">
          <nav>
            <div class="section">
              <a href="/#competence">Compétences</a>
            </div>
            <div class="section">
              <a href="/#experience">Expériences</a>
            </div>
            <div class="section">
              <a href="/#formation">Formations</a>
            </div>
            <div class="section">
              <a href="/#footer">Contact</a>
            </div>
            <div class="btn_default section">
              <router-link to="/projets">Projets</router-link>
            </div>
          </nav>
        </div>
      </div>
      <router-link to="/"><img class="logo" src="../assets/img/logo.svg" alt="logo portfolio"></router-link>
    </div>
</template>

<script>
export default {
  name: 'HeaderM',
  mounted(){
    window.onscroll = function () {  
      document.getElementById("drop-menu-mobile").classList.remove("activeMenu");  
      document.getElementById("menu-mobile").classList.remove("activeMenu");
    } 
  },
  methods:{ 
    OpenMenu: function() {
      document.getElementById("drop-menu-mobile").classList.toggle("activeMenu");
      document.getElementById("menu-mobile").classList.toggle("activeMenu");
    }
  }
}
</script>