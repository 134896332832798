<template>
<div class="contain_content comp_bloc">
        <svg xmlns="http://www.w3.org/2000/svg" width="393.592" height="410.68" viewBox="0 0 393.592 410.68" id="shape3">
            <path id="Tracé_31" data-name="Tracé 31" d="M-1166,1006c-46-32-94-182-184-188s-110,118-62,188,2,208,110,220,280-20,256-90S-1120,1038-1166,1006Z" transform="translate(1437.324 -817.79)" fill="#f9f9f9"/>
        </svg>

        <div id="competence">
            <h2>Mes capacités spéciales</h2>
            <div id="list_comp">

                <div class="elem_list_comp">
                    <h3>Graphique</h3>
                    <div class="puce_list_comp">
                        <ul>
                            <li class="competence_txt"><div class="list-text"><p class="paragraph">Photoshop</p></div></li>
                            <li class="competence_txt"><div class="list-text"><p class="paragraph">Illustrator</p></div></li>
                            <li class="competence_txt"><div class="list-text"><p class="paragraph">Adobe XD</p></div></li>
                            <li class="competence_txt"><div class="list-text"><p class="paragraph">Figma</p></div></li>
                            <li class="competence_txt"><div class="list-text"><p class="paragraph">InDesign</p></div></li>
                        </ul>
                    </div>
                </div>

                <div class="elem_list_comp">
                    <h3>Frontend</h3>
                    <div class="puce_list_comp">
                        <ul>
                            <li class="competence_txt"><div class="list-text"><p class="paragraph">HTML/CSS/JS</p></div></li>
                            <li class="competence_txt"><div class="list-text"><p class="paragraph">VueJS</p></div></li>
                            <li class="competence_txt"><div class="list-text"><p class="paragraph">Wordpress</p></div></li>
                            <li class="competence_txt"><div class="list-text"><p class="paragraph">Prestashop</p></div></li>
                        </ul>
                    </div>
                </div>

                <div class="elem_list_comp">
                    <h3>Backend</h3>
                    <div class="puce_list_comp">
                        <ul>
                            <li class="competence_txt"><div class="list-text"><p class="paragraph">SQL/MySQL</p></div></li>
                            <li class="competence_txt"><div class="list-text"><p class="paragraph">Python</p></div></li>
                            <li class="competence_txt"><div class="list-text"><p class="paragraph">Wamp/Phpmyadmin</p></div></li>
                            <li class="competence_txt"><div class="list-text"><p class="paragraph">Orientée objet</p></div></li>
                        </ul>
                    </div>
                </div>

                <div class="elem_list_comp">
                    <h3>UX/UI</h3>
                    <div class="puce_list_comp">
                        <ul>
                            <li class="competence_txt"><div class="list-text"><p class="paragraph">Design Thinking</p></div></li>
                            <li class="competence_txt"><div class="list-text"><p class="paragraph">Storytelling</p></div></li>
                            <li class="competence_txt"><div class="list-text"><p class="paragraph">Persona</p></div></li>
                            <li class="competence_txt"><div class="list-text"><p class="paragraph">Gamification</p></div></li>
                            <li class="competence_txt"><div class="list-text"><p class="paragraph">Maquettage</p></div></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Competence',
}
</script>