import Vue from 'vue'
import VueRouter from 'vue-router'
import ListProjet from '../components/ListProjet.vue';
import FicheProjet from '../components/FicheProjet.vue';
import Home from '../Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/projets',
    name: 'projets',
    component: ListProjet,
  },
  {
    path: '/projets/:name',
    name: 'FicheProjet',
    component: FicheProjet,
  },
  {
    path: '/projets/:name',
    name: 'HomeProjet',
    component: FicheProjet,
  }
  
  
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})

export default router
