<template>
    <div id="list-projets">
        <MatchMedia query="(max-width: 760px)" v-slot="{ matches }">
          <HeaderM  v-if="matches" />
          <Header v-else />
        </MatchMedia> 

        <svg xmlns="http://www.w3.org/2000/svg" width="775.451" height="1075.421" viewBox="0 0 775.451 1075.421" id="shape1_bis">
          <path id="Tracé_29" data-name="Tracé 29" d="M-346,63.667C-678-56.333-528-110-774-48s-508,282-224,462,42,262,178,406,310,256,410,120c50.9-69.224,50.207-87.135,53.333-120C-353.679,788.588-346,741-346,741Z" transform="translate(1121.451 73.967)" fill="#fff2ec"/>
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" width="361.529" height="526.635" viewBox="0 0 361.529 526.635" id="shape8" class="shape">
            <path id="Tracé_30" data-name="Tracé 30" d="M-2267.333,382.333S-2296,390-2212,360s256-194,294-54-82,188-66,276-98,232-188,186-95.333-76.333-95.333-76.333Z" transform="translate(2272.051 -250.216)" fill="#5e5e5e"/>
        </svg>

        <div class="contain_content_project">
          <div id="project-text">

            <div class="big_text">
              <h1>Mes projets</h1>
            </div>

            <p class="paragraph">La pratique est le meilleur moyen d'affiner ses connaissances et de découvrir de nouvelles choses. Entre projets professionnels, scolaires et personnels, j'ai donc eu l'occasion de développer et d'étoffer pleinement  mes compétences.</p>
          </div>
        

          <!-- FILTER -->
          <div id="filter">
            <div class="card_filter">
                <ul>
                    <li class="filter" :id="'filter' + filt" v-for="filt in filters.a.filter" :key="filt" v-on:click=ClickFilter(filt)>
                    {{filt}}
                    </li> 
                </ul>
              </div>
        </div>


          <div id="ex_projet" class="listprojet">
            <div class="card_pro" v-for="pro in displayPro" :key="pro.id">

              <!-- IF Finished project : link to project page -->
              <router-link v-if="pro.finish==true" :to="{ name: 'FicheProjet', params: { id: pro, name: pro.id }}">
                <img class="card_img" :src="require('../assets/img/projets/'+pro.image.profile)" :alt="'projet ' + pro.titre">
                <div class="card_txt">
                  <h4>{{pro.titre}}</h4>

                  <p class="mini_paragraphe">{{pro.description.short}}</p>
                  
                  <div class="card_techno">
                    <div class="techno" v-for="tech in pro.techno" :key="tech">
                      {{tech}}
                    </div>
                  </div>
                  </div>
                </router-link>

              <!-- ELSE Unfinished project : no link -->
              <router-link v-else to="">
                <div class="inprogress">
                  <p>En cours</p>
                </div>
                <img class="card_img" :src="require('../assets/img/projets/'+pro.image.profile)" :alt="'projet ' + pro.titre">
                <div class="card_txt">
                  <h4>{{pro.titre}}</h4>

                  <p class="mini_paragraphe">{{pro.description.short}}</p>
                  
                  <div class="card_techno">
                    <div class="techno" v-for="tech in pro.techno" :key="tech">
                      {{tech}}
                    </div>
                  </div>
                  </div>
              </router-link>

            </div>
          </div>
          
        
        </div>

        <Footer/>






    </div>

      
</template>

<script>
import proData from '../assets/json/projects.json';
import filterpro from '../assets/json/filtre.json';

import { MatchMedia } from "vue-component-media-queries";
import Header from '../components/Header.vue';
import HeaderM from '../components/HeaderM.vue';
import Footer from '../components/Footer.vue';


export default {
  name: 'ListProjet',
  components: {
    MatchMedia,
    Header,
    HeaderM,
    Footer,
  },
  mounted(){
    window.addEventListener("resize", function() {
      if (window.matchMedia("(min-width: 800px)").matches) {
        this.scrollForm();
      }
    })
  },
  data() {
    return {
      pros: proData,
      filters : filterpro,
      tab_filtre: [],
      displayPro: proData,
    };
  },
  methods: {
    ClickFilter: function(id){
      document.getElementById('filter' + id).classList.toggle("active");

      if(document.getElementById('filter' + id).classList.contains("active")){
        this.tab_filtre.push(id);
      }else{
        this.tab_filtre.splice(this.tab_filtre.indexOf(id), 1);
      }


      if(this.tab_filtre.length==0){
        this.displayPro = this.pros;
      }else{

        this.displayPro = [];
        for(let j in this.pros){
          let verif = 0;
          for(let f in this.pros[j].techno){
            if(this.tab_filtre.includes(this.pros[j].techno[f])){
              verif = verif+1;
            }
          }

          if(verif==this.tab_filtre.length){
            this.displayPro.push(this.pros[j]);
          }
        }
      }
    },
     scrollForm: function() {
      let shape1 = document.getElementById("shape1");
      let shape8 = document.getElementById("shape8");

      window.addEventListener("scroll", function () {
        let value = window.scrollY;
        shape1.style.top = value * 0.3 + "px";
        shape8.style.top = value * 0.2 + "px";
      });

    } 
  },
}
</script>
