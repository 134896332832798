<template>
  <div id="app">
    <router-view/>
  </div>
</template>




<script>
export default {
  name: 'App',
}


// import { MediaQueryProvider, MatchMedia } from 'vue-component-media-queries'

</script>




<style>
  @import './assets/css/general.css';
  @import './assets/css/image.css';
  @import './assets/css/bouton.css';
  @import './assets/css/header.css';
  @import './assets/css/welcome.css';
  @import './assets/css/competence.css';
  @import './assets/css/experience.css';
  @import './assets/css/project.css';
  @import './assets/css/formation.css';
  @import './assets/css/footer.css';
  @import './assets/css/listeProjet.css';
  @import './assets/css/ficheProjet.css';


html {
	scroll-behavior: smooth;
}
</style>






