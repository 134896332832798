<template>
<div class="contain_exp">
    <svg xmlns="http://www.w3.org/2000/svg" width="737.98" height="745.583" viewBox="0 0 737.98 745.583" class="shape" id="shape4" data-scroll-speed="8">
      <path id="Tracé_32" data-name="Tracé 32" d="M-1910.522,1364C-2052,1434-2090,1560-2138,1670s-80,374,58,418,164-104,214-112,446,70,424-82-30-354-168-364S-1769.044,1294-1910.522,1364Z" transform="translate(2179.098 -1350.573)" fill="#fff2ec"/>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="235.423" height="326.253" viewBox="0 0 235.423 326.253" class="shape" id="shape5" data-scroll-speed="2">
      <path id="Tracé_36396" data-name="Tracé 36396" d="M-347.333,1320.333S-372,1250-448,1278s-68,82-112,138-20,170,50,154,109.333,18.333,162.667,27.667c1.333-69.333,0-142.667,0-154.667Z" transform="translate(582.163 -1271.413)" fill="#5e5e5e"/>
    </svg>

  <div class="contain_content" id="exp_container">
        <div id="experience">

      <div id="exp_txt">
            <h2>Mes expériences sur le terrain</h2>
            <p class="orange_txt"><span class="peach">Stage et alternance</span></p>
       </div>

      <div id="exp_slider">
            <div id="exp_slider_box">
              <MatchMedia query="(min-width: 800px)" v-slot="{ matches }">
                <div v-if="matches" class="timeline"></div>
              </MatchMedia> 
              <div class="exp_element" :id="'exp_element' + exp.id" v-for="exp in exps" :key="exp.id">
                <div class="card_exp" :id="'exp' + exp.id" >
                  <template>
                    <h3>{{exp.titre}} <span>chez {{exp.entreprise}}</span></h3>

                    <p class="info_exp"  v-if=exp.en_cours >Depuis {{exp.date_debut}} - en {{exp.contrat}}</p>
                    <p class="info_exp" v-else>Pendant {{exp.duree}} - en {{exp.contrat}}</p>
                    <hr>
                    <ul>
                      <li v-for="mission in exp.missions" :key="mission">
                        <div class="list-text">
                          <p class="paragraph">{{mission}}</p>
                        </div>
                      </li>
                    </ul>
                  </template>
                </div>
                <MatchMedia query="(min-width: 800px)" v-slot="{ matches }">
                  <div v-if="matches" class="date_exp_container">
                    <p class="date_exp"  v-if=exp.en_cours >En cours...</p>
                    <p class="date_exp" v-else>{{exp.date_debut}} - {{exp.date_fin}}</p>
                  </div>
                </MatchMedia> 
              </div>
            </div>

      </div>
    </div>
   </div>
   <MediaQueryProvider :queries="{ mobile: '(min-width: 800px)' }" fallback="mobile">
      <MatchMedia v-slot="{ mobile }">
        <img v-if="mobile" id="Hori" src="../assets/img/Main hori.png" alt="Cercle">
      </MatchMedia> 
    </MediaQueryProvider>
  </div>
</template>

<script>

import expData from '../assets/json/experiences.json';
import { MatchMedia, MediaQueryProvider } from "vue-component-media-queries";

export default {
  name: 'Experience',
  mounted(){
      this.scrollForm();
  },
  components: {
    MatchMedia,
    MediaQueryProvider,
  },
  data() {
    return {
      exps: expData,
      count: 1,
    };
  },
  methods: {
    scrollForm: function() {
      let shape1 = document.getElementById("shape1");
      let shape3 = document.getElementById("shape3");
      let shape4 = document.getElementById("shape4");
      let shape5 = document.getElementById("shape5");
      let shape7 = document.getElementById("shape7");

      window.addEventListener("scroll", function () {
        let value = window.scrollY;
        shape1.style.top = value * 0.3 + "px";
        shape3.style.top = value * -0.2 + "px";
        shape4.style.top = value * 0.2 + "px";
        shape5.style.bottom = value * 0.2 + "px";
        shape7.style.top = value * 0.1 + "px";
      });


    if(screen.width>800){
      let mainDouble = document.getElementById("ensembleMains");
      let mainHori = document.getElementById("Hori");
      let mainVerti = document.getElementById("Verti");

      document.onmousemove = function () {

          let x = event.clientX * 0.001;
          let y = event.clientY * 0.005;

          let v = event.clientX * 0.0005;
          let w = event.clientY * 0.0025;

          mainDouble.style.left = 0 + x + "%";
          mainDouble.style.top = 50 + y + "%";
          mainHori.style.left = 2 + v + "%";
          mainHori.style.bottom = 0 + w + "%";
          mainVerti.style.right = 15 + x + "%";
          mainVerti.style.top = 55 + y + "%";

          x = 0 + x;
          y = 50 + y;

          mainDouble.style.transform = "translate(" + x + ",-" + y + ")";
          mainHori.style.transform = "translate(" + x + ",-" + y + ")";
          mainVerti.style.transform = "translate(-" + x + ",-" + y + ")";

      }
    }

    }
  
  }
  
};



</script>


