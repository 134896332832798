<template>
    <div id="footer">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 45" class="back_footer">
          <ellipse cx="100" cy="50" rx="140" ry="50"/>
        </svg>
        <div class="footer_content">
          <div class="footer_content_txt">
                <MediaQueryProvider :queries="{ mobile: '(max-width: 800px)' }" fallback="mobile">
                  <MatchMedia v-slot="{ mobile }">
                    <div v-if="mobile" class="btn_cv">
                      <a href="/cv/CV_mobile.pdf" target="_blank">Télécharger mon CV</a>
                    </div>

                    <div v-else class="btn_cv">
                      <a href="/cv/CV_web.pdf" target="_blank">Télécharger mon CV</a>
                    </div>
                  </MatchMedia> 
                </MediaQueryProvider>
        
            <div id="footer-txt">
              <div class="footer_txt" id="footer_txt1">
                <h4>Informations</h4>
                <p class="paragraph">Ce site a pour but de mettre en avant mes compétences, mes projets mais également de m'apporter une meilleure visibilité auprès de potentiels clients ou recruteurs.</p>
              </div>
              <div class="footer_txt" id="footer_txt2">
                <h4>Contact</h4>
                <div class="footer_bulle_contain">
                    <a href="https://www.linkedin.com/in/maelyss-pelud/" target="_blank" class="footer_bulle">
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24.846" viewBox="0 0 26 24.846">
                        <path id="linkedin-icon-1-logo-svg-vector" d="M0,7.886a2.7,2.7,0,0,1,.878-2.07A3.222,3.222,0,0,1,3.162,5,3.126,3.126,0,0,1,5.4,5.8a2.831,2.831,0,0,1,.878,2.158,2.643,2.643,0,0,1-.853,2.008,3.228,3.228,0,0,1-2.309.828H3.087A3.071,3.071,0,0,1,.853,9.969,2.781,2.781,0,0,1,0,7.886Zm.326,21.96V13.081H5.9V29.846Zm8.658,0h5.571V20.485a3.267,3.267,0,0,1,1.267-2.8,2.727,2.727,0,0,1,1.794-.59q2.811,0,2.811,3.79v8.959H26V20.234A8.077,8.077,0,0,0,24.243,14.6,5.993,5.993,0,0,0,19.6,12.68a5.7,5.7,0,0,0-5.044,2.786v.05h-.025l.025-.05V13.081H8.985q.05.8.05,4.994T8.985,29.846Z" transform="translate(0 -5)"/>
                      </svg>
                    </a>
                    <a href="mailto:maelyss.pelud@gmail.com" target="_blank" class="footer_bulle">
                      <svg xmlns="http://www.w3.org/2000/svg" width="29.635" height="19.756" viewBox="0 0 29.635 19.756">
                        <g id="noun-mail-1559570" transform="translate(-131.602 -134.4)">
                          <path id="Tracé_88267" data-name="Tracé 88267" d="M133.882,134.4a2.289,2.289,0,0,0-.855.166l12.717,11.327a.949.949,0,0,0,1.377,0l12.692-11.327a2.289,2.289,0,0,0-.855-.166H133.882Zm-2.256,1.959a2.366,2.366,0,0,0-.024.321v15.2a2.284,2.284,0,0,0,.178.879l8.774-8.43Zm29.588,0-8.9,7.955,8.762,8.43a2.293,2.293,0,0,0,.166-.867v-15.2a2.412,2.412,0,0,0-.024-.321ZM150.6,145.845,148.64,147.6a3.343,3.343,0,0,1-4.417,0l-1.959-1.745-8.644,8.288a2.363,2.363,0,0,0,.261.012h25.076c.083,0,.169,0,.249-.012l-8.608-8.3Z" transform="translate(0 0)"/>
                        </g>
                      </svg>
                    </a>
                    <a href="tel:+33651747287" target="_blank" class="footer_bulle">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24.942" height="24.945" viewBox="0 0 24.942 24.945">
                        <g id="noun-phone-4549027" transform="translate(-111.997 -42.006)">
                          <path id="Tracé_88304" data-name="Tracé 88304" d="M135.987,60.021l-5.887-1.9a1.652,1.652,0,0,0-1.752.484l-1.268,1.453a1.017,1.017,0,0,1-1.318.182,24.065,24.065,0,0,1-3.9-3.155,24.341,24.341,0,0,1-3.155-3.9,1.02,1.02,0,0,1,.182-1.318l1.453-1.268a1.648,1.648,0,0,0,.484-1.752l-1.9-5.884a1.375,1.375,0,0,0-1.309-.953,1.508,1.508,0,0,0-.161.009l-2.544.3a2.052,2.052,0,0,0-1.385.786c-2.092,2.759-1.652,6.095-.913,8.411a23.355,23.355,0,0,0,5.814,9.012,23.4,23.4,0,0,0,9.012,5.814c2.315.74,5.652,1.183,8.407-.913a2.052,2.052,0,0,0,.786-1.385l.3-2.544a1.377,1.377,0,0,0-.942-1.473Z" transform="translate(0 0)"/>
                          <path id="Tracé_88305" data-name="Tracé 88305" d="M357.661,151.621a4.291,4.291,0,0,1,4.287,4.287.719.719,0,0,0,1.438,0,5.721,5.721,0,0,0-5.722-5.722.717.717,0,0,0,0,1.435Z" transform="translate(-232.112 -102.511)"/>
                          <path id="Tracé_88306" data-name="Tracé 88306" d="M357.656,55.694a9.325,9.325,0,0,1,9.314,9.314.719.719,0,1,0,1.438,0,10.741,10.741,0,0,0-10.749-10.749.718.718,0,1,0,0,1.435Z" transform="translate(-232.107 -11.611)"/>
                        </g>
                      </svg>
                    </a>
                </div>

              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { MatchMedia, MediaQueryProvider } from "vue-component-media-queries";

export default {
  name: 'Footer',
    components: {
    MatchMedia,
    MediaQueryProvider,
  },
}
</script>