<template>
    <div id="fiche-projets">
        <MatchMedia query="(max-width: 760px)" v-slot="{ matches }">
          <HeaderM  v-if="matches" />
          <Header v-else />
        </MatchMedia> 

        <svg xmlns="http://www.w3.org/2000/svg" width="775.451" height="1075.421" viewBox="0 0 775.451 1075.421" id="shape9">
          <path id="Tracé_29" data-name="Tracé 29" d="M-346,63.667C-678-56.333-528-110-774-48s-508,282-224,462,42,262,178,406,310,256,410,120c50.9-69.224,50.207-87.135,53.333-120C-353.679,788.588-346,741-346,741Z" transform="translate(1121.451 73.967)" fill="#fff2ec"/>
        </svg>


        <div class="contain_content_project">
          <div class="fiche-project-text">

            <div class="return_text">
              <router-link to="/projets"><p>&#9668; <span>Retour aux projets</span></p></router-link>
            </div>

            <div class="big_text">
              <h1>{{this.projOpen.titre}}</h1>
            </div> 
            <p class="paragraph">{{this.projOpen.description.long}}</p>
            <div id="btn_project" class="btn_small" v-if="this.projOpen.link">
                  <a :href="this.projOpen.link" target="_blank">Voir le site</a>
                </div>
          </div>


          <div class="fiche-projet-desc">
            <h2>Description</h2>
            <div class="projet-desc-container">

              <div class="projet-desc-element">
                <h3>Missions</h3>
                <li v-for="mission in this.projOpen.mission" :key="mission" class="data-list">
                  <div class="list-text">
                    <p class="paragraph">{{mission}}</p>
                  </div>
                </li>
              </div>

              <div class="projet-desc-element">
                <h3>Outils</h3>
                <li v-for="tech in this.projOpen.technologie" :key="tech" class="data-list">
                  <div class="list-text">
                    <p class="paragraph">{{tech}}</p>
                  </div>
                </li>
              </div>

            </div>
          </div>


          <div class="fiche-projet-img">
            <h2>Imagerie</h2>
            <div class="content_image">
                <a :href="require('../assets/img/projets/'+ima)" target="_blank" v-for="ima in this.projOpen.image.portfolio" :key="ima">
                  <img class="img_projet" :src="require('../assets/img/projets/'+ima)">
                </a>
              </div>
          </div>
        


 <!-- 
          <div id="ex_projet" class="listprojet">
            <div class="card_pro" v-for="pro in displayPro" :key="pro.id" v-on:click=ClickProject(pro.id)>
                <img class="card_img" :src="require('../assets/img/projets/'+pro.image.profile)" :alt="'projet ' + pro.titre">
                <div class="card_txt">
                  <h4>{{pro.titre}}</h4>

                  <p class="mini_paragraphe">{{pro.description.short}}</p>
                  
                  <div class="card_techno">
                    <div class="techno" v-for="tech in pro.techno" :key="tech">
                      {{tech}}
                    </div>
                  </div>
                  </div>
            </div>
          </div>

          
            <div class="card_detail_pro" :id="'project' + pro.id" v-for="pro in displayPro" :key="pro.id">
              <div class="modal_detail_pro" :id="'modal' + pro.id" v-on:click=CloseProject(pro.id)></div>
              <div id="detail_projet" class="detail_projet">

                <div class="col_detail_project" id="col2_detail_project">
                  <h4>{{pro.titre}}</h4> 

                  <p class="mini_paragraphe">{{pro.description.long}}</p>

                  <div class="card_techno techno_detail_pro">
                    <div class="techno" v-for="tech in pro.techno" :key="tech">
                      {{tech}}
                    </div>
                  </div>
                  <div class="btn_link_detail">
                    <div class="btn_default link_detail_pro">
                      <a href="">Voir la démo</a>
                    </div>
                  </div>
                </div>

                <div class="col_detail_project" id="col1_detail_project">
                  <div class="exit_detail_pro" v-on:click=CloseProject(pro.id)>X</div>
                  <img class="detail_img" :src="require('../assets/img/projets/'+pro.image.profile)" :alt="'projet ' + pro.titre">
                </div>
              </div>
            </div>
          
         -->
        </div>

        <Footer/>






    </div>

      
</template>

<script>
import proData from '../assets/json/projects.json';
import filterpro from '../assets/json/filtre.json';

import { MatchMedia } from "vue-component-media-queries";
import Header from '../components/Header.vue';
import HeaderM from '../components/HeaderM.vue';
import Footer from '../components/Footer.vue';



export default {
  name: 'FicheProjet',
  created(){

    if(this.$route.params.id == !undefined){
      this.projOpen = this.$route.params.id;
    }else{
      let urlcourante = window.location.href.replace(/\/$/, "");
      let queue_url = urlcourante.substring (urlcourante.lastIndexOf( "/" )+1 );

      for(let j in this.pros){
        if(this.pros[j].id==queue_url){
          this.projOpen = this.pros[j];
        }
      }
    }
    
  },
  components: {
    MatchMedia,
    Header,
    HeaderM,
    Footer,
  },
  data() {
    return {
      pros: proData,
      filters : filterpro,
      tab_filtre: [],
      displayPro: proData,
      projOpen : null,
    };
  },
  methods: {
  },
}

</script>
