<template>
<div class="band_proj">
    <div id="projet" class="contain_content">

      <div id="pro_txt">
            <h2>Mes projets réalisés</h2>
            <p class="paragraph">La pratique est le meilleur moyen d'affiner ses connaissances et de découvrir de nouvelles choses. Entre projets professionnels, scolaires et personnels, j'ai donc eu l'occasion de développer et d'étoffer pleinement  mes compétences.</p>
            <div class="btn_second">
              <router-link to="/projets">Projets</router-link>
            </div>
      </div>

  <MediaQueryProvider :queries="{ mobile: '(min-width: 800px)' }" fallback="mobile">
      <MatchMedia v-slot="{ mobile }">
      <div v-if="mobile" id="ex_projet">
        <div class="card_pro">
          <router-link :to="{ name: 'HomeProjet', params: { id: proj1, name: proj1.id }}">
            <img class="card_img" :src="require('../assets/img/projets/'+proj1.image.profile)" :alt="'projet ' + proj1.titre">
            <div class="card_txt">
              <h4>{{proj1.titre}}</h4>

              <p class="mini_paragraphe">{{proj1.description.short}}</p>
              
              <div class="card_techno">
                <div class="techno" v-for="tech in proj1.techno" :key="tech">
                  {{tech}}
                </div>
              </div>
              </div>
          </router-link>
        </div>

        <div class="card_pro">
          <router-link :to="{ name: 'HomeProjet', params: { id: proj2, name: proj2.id }}">
            <img class="card_img" :src="require('../assets/img/projets/'+proj2.image.profile)" :alt="'projet ' + proj2.titre">
            <div class="card_txt">
              <h4>{{proj2.titre}}</h4>

              <p class="mini_paragraphe">{{proj2.description.short}}</p>
              
              <div class="card_techno">
                <div class="techno" v-for="tech in proj2.techno" :key="tech">
                  {{tech}}
                </div>
              </div>
              </div>
          </router-link>
        </div>

      </div>
  </MatchMedia> 
</MediaQueryProvider>
    
    </div>


  </div>
</template>


<script>

import proData from '../assets/json/projects.json';
import { MatchMedia, MediaQueryProvider } from "vue-component-media-queries";

export default {
  name: 'Project',
  components: {
    MatchMedia,
    MediaQueryProvider,
  },
  data() {
    return {
      pros: proData,
      proj1: proData.k,
      proj2: proData.j,
    };
  },
};
 
</script>
