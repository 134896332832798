<template>
<div class="contain_for">
    <div class="contain_content">
        <div id="formation">
            <MediaQueryProvider :queries="{ mobile: '(min-width: 800px)' }" fallback="mobile">
                <MatchMedia v-slot="{ mobile }">
                <div v-if="mobile" id="for_list">

                    <div id="first_col" class="for_col">
                        <div class="card_for">
                            <p class="nb_for">4.</p>
                            <p class="txt_for">Master Expert en Stratégie Digitale spécialité UX Design</p>
                        </div>
                        <div class="card_for">
                            <p class="nb_for">2.</p>
                            <p class="txt_for">BTS Services Informatiques aux Organisations spécialité SLAM</p>
                        </div>
                    </div>

                    <div id="second_col" class="for_col">
                        <div class="card_for">
                            <p class="nb_for">3.</p>
                            <p class="txt_for">Bachelor Chef de Projets Informatiques</p>
                        </div>
                        <div class="card_for">
                            <p class="nb_for">1.</p>
                            <p class="txt_for">Baccalauréat Scientifique spécialité Sciences de l'Ingénieur</p>
                        </div>
                    </div>

                </div>

                <div v-else id="for_list_mobile">
                        <div class="card_for">
                            <p class="nb_for">4.</p>
                            <p class="txt_for">Master Expert en Stratégie Digitale spécialité UX Design</p>
                        </div>
                        <div class="card_for">
                            <p class="nb_for">3.</p>
                            <p class="txt_for">Bachelor Chef de Projets Informatiques</p>
                        </div>
                        <div class="card_for">
                            <p class="nb_for">2.</p>
                            <p class="txt_for">BTS Services Informatiques aux Organisations spécialité SLAM</p>
                        </div>
                        <div class="card_for">
                            <p class="nb_for">1.</p>
                            <p class="txt_for">Baccalauréat Scientifique spécialité Sciences de l'Ingénieur</p>
                        </div>
                </div>
            </MatchMedia>
        </MediaQueryProvider> 


            <div id="for_txt">
                <h2>Mon cursus magique</h2>
                <p class="orange_txt"><span class="peach">Bac +5</span></p>
                <p class="paragraph">Grâce à mon parcours scolaire, j'ai une vision 360 de l'informatique. Bien que mon but final est d'exercer en tant qu'intégratrice web, mon cursus m'apporte un panel de compétences plus développées, me permettant de voir plus large pour mes emplois futurs.</p>
                
            <MediaQueryProvider :queries="{ mobile: '(min-width: 800px)' }" fallback="mobile">
                <MatchMedia v-slot="{ mobile }">
                    <img v-if="mobile" id="Verti" src="../assets/img/Main verti.png" alt="Cercle">
                </MatchMedia> 
            </MediaQueryProvider>
        </div>

        </div>
    </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="859.687" height="824.737" viewBox="0 0 859.687 824.737" id="shape6">
            <path id="Tracé_36397" data-name="Tracé 36397" d="M-347.333,2404S-504,2170-672,2210s-168,112-338,194-282,306-114,370,196,230,380,254,108-161.906,226-171.906,170.667-101.333,170.667-101.333Z" transform="translate(1207.021 -2205.4)" fill="#f9f9f9"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="327.037" height="349.69" viewBox="0 0 327.037 349.69" id="shape7">
            <path id="Tracé_88266" data-name="Tracé 88266" d="M-2267.333,2955.76S-2268,2916-2176,2932s100-48,184,0,64,152-48,164-123.333,195.76-227.333,163.76C-2267,3214.094-2267.333,2955.76-2267.333,2955.76Z" transform="translate(2267.333 -2913.806)" fill="#5e5e5e"/>
        </svg>



</div>
</template>

<script>
import { MatchMedia, MediaQueryProvider } from "vue-component-media-queries";

export default {
  name: 'Formation',
  components: {
    MatchMedia,
    MediaQueryProvider,
  }
}
</script>