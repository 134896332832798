<template>
    <div id="home">

      <!----- HEADER ----->
        <MatchMedia query="(max-width: 800px)" v-slot="{ matches }">
          <HeaderM  v-if="matches" />
          <Header v-else />
        </MatchMedia> 

      


      <!----- CONTENT ----->
      <div id="content" class="content">

        <!----- Welcome Text ----->
        <div class="welcome_bloc">

          <svg xmlns="http://www.w3.org/2000/svg" width="775.451" height="1075.421" viewBox="0 0 775.451 1075.421" id="shape1" class="shape">
            <path id="Tracé_29" data-name="Tracé 29" d="M-346,63.667C-678-56.333-528-110-774-48s-508,282-224,462,42,262,178,406,310,256,410,120c50.9-69.224,50.207-87.135,53.333-120C-353.679,788.588-346,741-346,741Z" transform="translate(1121.451 73.967)" fill="#fff2ec"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="361.529" height="526.635" viewBox="0 0 361.529 526.635" id="shape2" class="shape">
            <path id="Tracé_30" data-name="Tracé 30" d="M-2267.333,382.333S-2296,390-2212,360s256-194,294-54-82,188-66,276-98,232-188,186-95.333-76.333-95.333-76.333Z" transform="translate(2272.051 -250.216)" fill="#5e5e5e"/>
          </svg>

          <div class="welcome_content">

            <div id="front-text" class="welcome_left">
              <div class="big_text">
                <h1>Maëlyss Pelud</h1>
                <p class="large_text"><span class="peach">Intégratrice Web</span></p>
              </div>

              <p class="paragraph">L'univers du développement me  fascine,  c'est un puits de connaissances sans fin! Entre de nouveaux  environnements, de  nouveaux  langages... impossible de s'ennuyer! Incompréhensible pour le commun des mortels, j'aime voir la magie du code qui opère.  M'épanouir dans ce domaine n'est qu'une simple évidence.</p>

              <div id="front_btn">
                <div id="first_small" class="btn_small">
                  <a href="#experience">Intégration</a>
                </div>
                <div id="second_small" class="btn_small">
                  <a href="#competence">UX/UI</a>
                </div>
              </div>

            </div>

            <div id="front-text" class="welcome_img">
                  <img id="ensembleMains" src="./assets/img/EnsembleMains.png" alt="Cercle">            
            </div>
          </div>
        </div>


        <!----- Competence ----->
        <Competence/>


        <Experience/>     


        <Project/>
        <Formation/>
        
      </div>


      <Footer/>


    
    <!--<img class="cercle" id="four" src="./assets/img/Cercle.svg" alt="Cercle"> -->

  </div>
</template>

<script>


import { MatchMedia} from "vue-component-media-queries";
import Experience from './components/Experience.vue';
import Competence from './components/Competence.vue';
import Header from './components/Header.vue';
import Project from './components/Project.vue';
import Formation from './components/Formation.vue';
import Footer from './components/Footer.vue';

import HeaderM from './components/HeaderM.vue';

export default {
  name: 'Home',
  mounted(){
      this.scrollForm();
  },
  components: {
    MatchMedia,
    Experience,
    HeaderM,
    Competence,
    Header,
    Project,
    Formation,
    Footer,
  },
  methods:{ 
    scrollForm: function() {
      let shape1 = document.getElementById("shape1");
      let shape3 = document.getElementById("shape3");
      let shape4 = document.getElementById("shape4");
      let shape5 = document.getElementById("shape5");
      let shape7 = document.getElementById("shape7");

      window.addEventListener("scroll", function () {
        let value = window.scrollY;
        console.log("hey");
        shape1.style.top = value * 0.3 + "px";
        shape3.style.top = value * -0.2 + "px";
        shape4.style.top = value * 0.2 + "px";
        shape5.style.bottom = value * 0.2 + "px";
        shape7.style.top = value * 0.1 + "px";
      });


    if(screen.width>800){
      let mainDouble = document.getElementById("ensembleMains");
      let mainHori = document.getElementById("Hori");
      let mainVerti = document.getElementById("Verti");

      document.onmousemove = function () {

          let x = event.clientX * 0.001;
          let y = event.clientY * 0.005;

          let v = event.clientX * 0.0005;
          let w = event.clientY * 0.0025;

          mainDouble.style.left = 0 + x + "%";
          mainDouble.style.top = 50 + y + "%";
          mainHori.style.left = 2 + v + "%";
          mainHori.style.bottom = 0 + w + "%";
          mainVerti.style.right = 15 + x + "%";
          mainVerti.style.top = 55 + y + "%";

          x = 0 + x;
          y = 50 + y;

          mainDouble.style.transform = "translate(" + x + ",-" + y + ")";
          mainHori.style.transform = "translate(" + x + ",-" + y + ")";
          mainVerti.style.transform = "translate(-" + x + ",-" + y + ")";

      }
    }

    } 
  
  },
}



</script>

